import numFormat from 'number-format'
import moment from 'moment'

/// Check for Empty Object
export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export function removeArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
};

export function compareArray (value, other) {

    if (!Array.isArray(value)) 
    {
        console.log("Array 1 is not an array", value)
        return false
    }
    if (!Array.isArray(other)) 
    {
        console.log("Array 2 is not an array", other)
        return false
    }
    if(!value  || !other) {
        console.log("Both is not an array", other)
        return false
    }

	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {

		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!compareArray(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};


export function compareArray2(arr1, arr2) {
  
    if (!Array.isArray(arr1)) 
    {
        console.log("Array 1 is not an array", arr1)
        return false
    }
    if (!Array.isArray(arr2)) 
    {
        console.log("Array 2 is not an array", arr2)
        return false
    }
    if(!arr1  || !arr2) {
        console.log("Both is not an array", arr2)
        return false
    }
        
   
     let result;
   
   arr1.forEach((e1,i)=>arr2.forEach(e2=>{
     
          if(e1.length > 1 && e2.length){
             result = compareArray(e1,e2);
          }else if(e1 !== e2 ){
             result = false
          }else{
             result = true
          }
     })
   )
   
   return result
   
 }

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function hasClass2(element, className) {
    return element.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(element.className);
}


export function toggleClass(el, className) {
    var pattern = new RegExp('(^|\\s)' + className + '(\\s|$)');
    if (pattern.test(el.className)) {
        el.className = el.className.replace(pattern, ' ');
    } else {
        el.className += ' ' + className;
    }
};



export function formatCurrency(Amount, format, code) {
    // console.log(format)
    //console.log(numFormat(format, Amount))
    var DecimalSeparator = Number("1.2").toLocaleString().substr(1, 1);
    var AmountWithCommas = Amount.toLocaleString();
    var arParts = String(AmountWithCommas).split(DecimalSeparator);
    var intPart = arParts[0];
    var decPart = (arParts.length > 1 ? arParts[1] : '');
    decPart = (decPart + '00').substr(0, 2);
    if (!code) {
        code = 'NGN '
    }
    if (format) {
        return numFormat(format, Amount)
    }

    return code + ' ' + intPart + DecimalSeparator + decPart;
}

export function checkComponentValidation(validations) {
    //console.log((validations))
    //console.log(Object.values(validations))
    const validation = {}
    var values = Object.values(validations);
    for (let o of values) {
        if (o.isValid === false) {
            return o;
        }
        else {
            return { isValid: true, message: "" }
        }
    }
}


export function clearSessionStorage() {

}

export function GetTimeInWords(value) {
    if (value <= 0) {
        return "0 secs";
    }
    var diff = moment.duration(value, 'minutes');
    if (diff.days() > 1) {
        return diff.days() + " days"
    }
    if (diff.days() === 1) {
        return diff.days() + " day"
    }
    if (diff.hours() > 1) {
        return diff.hours() + " hours"
    }
    if (diff.hours() === 1) {
        return diff.hours() + " hour"
    }
    if (diff.minutes() > 1) {
        return diff.minutes() + " mins"
    }
    if (diff.minutes() === 1) {
        return diff.minutes() + " min"
    }
    if (diff.minutes() < 1) {
        return "1 min"
    }
    return "0 secs";
}

export function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}


export function toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const SyncColors = {
    names: {
        aqua: "#00ffff",
        black: "#000000",
        blue: "#0000ff",
        brown: "#a52a2a",
        cyan: "#00ffff",
        darkblue: "#00008b",
        darkcyan: "#008b8b",
        darkgrey: "#a9a9a9",
        darkgreen: "#006400",
        darkkhaki: "#bdb76b",
        darkmagenta: "#8b008b",
        darkolivegreen: "#556b2f",
        darkorange: "#ff8c00",
        darkorchid: "#9932cc",
        darkred: "#8b0000",
        darksalmon: "#e9967a",
        darkviolet: "#9400d3",
        fuchsia: "#ff00ff",
        gold: "#ffd700",
        green: "#008000",
        indigo: "#4b0082",
        khaki: "#f0e68c",
        lightblue: "#add8e6",
        lightgreen: "#90ee90",
        lightgrey: "#d3d3d3",
        lightpink: "#ffb6c1",
        lime: "#00ff00",
        magenta: "#ff00ff",
        maroon: "#800000",
        navy: "#000080",
        olive: "#808000",
        orange: "#ffa500",
        pink: "#ffc0cb",
        purple: "#800080",
        violet: "#800080",
        red: "#ff0000",
        silver: "#c0c0c0",
    },
    random: () => {
        var result;
        var count = 0;
        for (var prop in SyncColors.names)
            if (Math.random() < 1/++count)
               result = prop;
               console.log(result)
               //console.log(SyncColors.names.length)
        return { name: result, rgb: SyncColors.names[result], hex: SyncColors.names[result]};
    },
    getColor: (value = 0) => {
        if (value <= 0)
        {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"]};
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length)
        {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color]};
        }
      
        if (value >= colorKeys.length)
        {
            var i = value;
            do{
                i = (i % colorKeys.length)
            } while( i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color]};
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"]};
    },
    getColorFromString: (stringValue = "null") => {
        if (isNullOrEmpty(stringValue))
        {
            stringValue = "null";
        }
        var value = Math.abs(hashCode(stringValue));
        //console.log(value.toString().length)
        if (value.toString().length > 5)
        {
            value = parseInt(value.toString().substr(0, 2))
        }
        if (value <= 0)
        {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"]};
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length)
        {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color]};
        }
      
        if (value >= colorKeys.length)
        {
            var i = value;
            do{
                i = (i % colorKeys.length)
            } while( i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color]};
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"]};
    }
}

function getlength(number) {
    return number.toString().length;
}

export function hashCode(s) {
    for(var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
}

export function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }


  export function copyToClipboard (text) {
    var dummy = document.createElement("textarea");
    //dummy.style.display = 'none';
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

function toCamelCase(str) {
    return str.replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();        
    });
};

String.prototype.toCamelCase = function() {
    return this.replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();        
    });
};

export function toPascalCase(string) {
    return `${string}`
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w+)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
      )
      .replace(new RegExp(/\s/, 'g'), '')
      .replace(new RegExp(/\w/), s => s.toUpperCase());
  }

export function isNullOrEmpty (value){
    return (!value);
}

export function parseBool(v){ return v==="false" || v==="False"  || v==="null" || v==="NaN" || v==="undefined" || v==="0" ? false : !!v; }

// Object.prototype.isNullOrEmpty = function(value){
//     return (!value);
// }

export function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        console.log("Retrying")
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
                console.log("Retrying maximum retries exceeded")
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }
  
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }


 export function download(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
    return false;
  }

  export function downloadFile(url, filename) {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
    }


    export const httpHelpers = {
        // Main wrapper for Fetch API
        httpRequest: (url, method, payload, headers) => {
          // Configuration to accept json as a default
          const config = {
            method,
            headers: {
              'Content-Type': 'application/json'
            }
          };
          // method = post and payload, add it to the fetch request
          if (method.toLowerCase() === 'post' &&  payload && payload.length > 0) {
            config.body = JSON.stringify(payload);
          }
          // if custom headers need to be set for the specific request
          // override them here
          if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
            config.headers = headers;
          }
          return fetch(
            url,
            config
          ).then((response) => {
       
            // Check if the request is 200
            if (response.ok) {
              let data = response;
              
              // if the type is json return, interpret it as json
              if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
       
                data = response.json();
              }
              return data;
            }
      // if an errors, anything but 200 then reject with the actuall response
            return Promise.reject(response);
          });
        },  
      };


