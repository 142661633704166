import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'
import { retry } from './utils/SyncUtils'
import { ErrorFallback } from './components/UtilComponents';
// import Main from './home/TestMain'
// import DefaultLayout from './containers/TheLayout'
import { SecuredRoute } from './auth/components'


const DefaultLayout = React.lazy(() => retry(() => import('./containers/TheLayout')));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Main = React.lazy(() => retry(() => import('./home/Main')));


function AppRoutes(props) {

    const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>


    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Switch>


                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                {/*
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/dl">
                    <Login />
                </Route>
                <Route path="/callback">
                    <Callback />
                </Route>
                <Route path="/silent_callback">
                    <SilentRenew />
                </Route>
                <Route path="/silent_renew.html">
                    <SilentRenew />
                </Route>
                <Route path="/silent_renew">
                    <SilentRenew />
                </Route> */}

                {/* <Route exact path="/register" name="Register Page" render={props => <SignUp {...props} />} />
                <Route exact path="/signup" name="SignUp Page" render={props => <SignUp {...props} />} /> */}

                {/* <SecuredRoute path="/dashboard">
                    <DefaultLayout {...props} />
                </SecuredRoute> */}
                <SecuredRoute path="/dashboard">
                    <DefaultLayout {...props} />
                </SecuredRoute>
                <SecuredRoute path="/console">
                    <DefaultLayout {...props} />
                </SecuredRoute>
                <Route path="/">
                    <Suspense fallback={loading()}>
                        <Main />
                        {/* <DefaultLayout {...props} /> */}
                    </Suspense>

                </Route>
            </Switch>
        </ErrorBoundary>
    )

}

export default AppRoutes;